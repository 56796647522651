@font-face {
  font-family: 'YouSheBiaoTiHei';
  src: url(https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/YouSheBiaoTiHei.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Multi', sans-serif;
  background: rgb(255,255,255);
  color: white;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: rgb(0,0,0,0);
  /* text-align: center; */
}

.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.hide {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
  transition: all 2s;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: white;
}

.input {
  outline: none;
  color: #333;
  font-size: 1rem;
	margin: 0 auto;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  transition: all 0.3s;
}

.label {
  font-size: 1rem;
  display: block;
  transition: all 0.3s;
  position: relative;
  top: -60px;
  transform: translateY(0rem);
}

.input:placeholder-shown + .label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 100px;
  color:white;
  background-color: #275efe;
  /* filter: drop-shadow(0 2px 8px rgba(#275efe, 0.32)); */
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

button:hover {
  transform: translateY(-2px);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
}

button:active {
  transform: translate(1px);
}

button:focus {
  border: none;
}

.music {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

@keyframes left-move {
  0% { transform: translateX(0px); }
  50% { transform: translateX(-5px); }
  100% { transform: translateX(0px);}
}

@keyframes right-move {
  0% { transform: translateX(0px); }
  50% { transform: translateX(5px); }
  100% { transform: translateX(0px);}
}

.left-arrow {
  animation: left-move 1s ease-out infinite;
}

.right-arrow {
  animation: right-move 1s ease-out infinite;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

